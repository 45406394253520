import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from "styled-components";
import { ArrowLeftCircle, ArrowRightCircle, PlusCircle, MinusCircle, BookOpen, Volume2, VolumeX, File, MessageSquare } from "react-feather";
import { useTranslation } from 'react-i18next';
import { initializeApp } from "firebase/app";
import { ref, push, getDatabase } from "firebase/database";
import i18n from './i18n';
import ReactGA from "react-ga4";
import './App.css';

function App() {
  const { t } = useTranslation();
  const firebaseConfig = {
    apiKey: "AIzaSyAtiTVa3QgKcGr0M6TDNxXr6nna1J_Vid0",
    authDomain: "dailyrecovery-8b608.firebaseapp.com",
    projectId: "dailyrecovery-8b608",
    storageBucket: "dailyrecovery-8b608.appspot.com",
    messagingSenderId: "558339934830",
    appId: "1:558339934830:web:078c6c970d36bf1cddf47f",
    databaseURL: "https://dailyrecovery-8b608-default-rtdb.europe-west1.firebasedatabase.app/",
    measurementId: "G-33H0V69K5J"
  };
  const database = getDatabase(initializeApp(firebaseConfig)); // Initialize Firebase and get the database reference
  const dataRef = ref(database, 'userContacts'); // Define a reference to the 'userContacts' node in the database
  const [articles, setArticles] = useState([]);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(null);
  const [articleSourceType, setArticleSourceType] = useState('articles'); // Default article source type
  const [textSize, setTextSize] = useState(16); // Default text size
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  //const initialSource = `articles_${i18n.language}.json`;
  //const fetchSource = `/articles_${i18n.language}.json`;
  //const [source, setSource] = useState(initialSource);
  const [windowDimension, setWindowDimension] = useState(null);
  const [speechSynthesisUtterance, setSpeechSynthesisUtterance] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false); // New state to track speech status
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [language, setLanguage] = useState('');
  const handleClosePopup = () => setShowPopup(false);
  initializeApp(firebaseConfig);
  function getPreferredLanguage() {
    const preferredLanguage = localStorage.getItem('language');
    return preferredLanguage;
  }

  useEffect(() => {
    const language = window.location.pathname.includes('/nl') ? 'nl' : window.location.pathname.includes('/fr') ? 'fr' : 'en';
    i18n.changeLanguage(language);
    if (window.location.pathname.includes('/nl') || window.location.pathname.includes('/fr')) {
      localStorage.setItem('language', language); // Set language in localStorage
    }
  }, []);

  useEffect(() => {
    // Check if the user has visited the site before
    const visitedBefore = localStorage.getItem('visitedBefore');
    if (!visitedBefore) {
      // Show email popup if it's the first visit
      setShowEmailPopup(true);
    }

    // Check if language preference is already set
    const savedLanguage = localStorage.getItem('language');
    const preferredLanguage = getPreferredLanguage(); // Get preferred language from local storage

    if (!savedLanguage && window.location.pathname === '/') {
      // Show language popup if language is not set and the path is "/"
      setShowLanguagePopup(true);
    } else if (window.location.pathname === '/') {
      // If language is not set and the path is "/", redirect to the preferred language version
      if (preferredLanguage && preferredLanguage !== 'en') {
        window.location.href = `/${preferredLanguage}`; // Redirect to the preferred language version
      }
    } else {
      // Set language based on the path or preferred language
      const languageFromPath = window.location.pathname.includes('/nl') ? 'nl' : 'en';
      const languageToUse = languageFromPath === preferredLanguage ? preferredLanguage : languageFromPath;
      setLanguage(languageToUse);
      i18n.changeLanguage(languageToUse);
    }
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Save email to local storage and hide email popup
    localStorage.setItem('visitedBefore', true);
    localStorage.setItem('email', email);
    localStorage.setItem('phone', phone);
    const userData = {
      email: email,
      phone: phone,
      language: language,
      subscriberEmail: email
    };
   // Check if either email or phone is filled in before pushing data
  if (email || phone) {
    push(dataRef, userData)
      .then(() => {
        console.log("Data submitted successfully!");
        setShowEmailPopup(false);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  }

    setShowEmailPopup(false);

  };

  const handleLanguageSelect = (selectedLanguage) => {
    // Save selected language to local storage and hide language popup
    localStorage.setItem('language', selectedLanguage);
    setShowLanguagePopup(false);
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  const copyArticleToClipboard = () => {
    if (currentArticleIndex !== null) {
      const article = articles[currentArticleIndex];
      const fullText = `${article.Title}.\n\n${article.Intro}.\n\n${article.Article}.\n\n${article.Quote}\n\n${t('readdaily')}`;
      navigator.clipboard.writeText(fullText).then(() => {
        setPopupMessage(t('copyArticle'));
        setShowPopup(true);
        console.log('Show Popup');
        setTimeout(() => setShowPopup(false), 3000); // Auto-hide the popup after 3 seconds

      }).catch(err => {
        console.error('Failed to copy article to clipboard: ', err);
      });
    }
  };

  const PopupBanner = ({ message, onClose }) => {
    return (
      <div className="popupBanner">
        <p className="popupMessageClass">{message}</p>
              <span className="closeIcon" onClick={onClose}>&times;</span> {/* Use &times; for "X" */}
      </div>
    );
  };

  //GA4
  useEffect(() => {
    ReactGA.initialize("G-ZKB8FKHGRJ");
  }, []);

  //Mobile Menu
  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  //Mobile Menu
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const toggleSource = () => {
    // Extract the language from the current source
    const language = articleSourceType.includes('nl') ? 'nl' : 'en';

    if (articleSourceType === 'articles') {
      setArticleSourceType('sp'); // Change to the spiritual principle source
    } else {
      setArticleSourceType('articles'); // Change to the regular article source
    }
    setCurrentArticleIndex(null); // Reset the current article index
  };
  // Dynamically set button labels based on article source type
  const getSourceLabel = () => {
      return articleSourceType === 'articles' ? t('SP') : t('JFTD');
  };

 // Article Logic
  useEffect(() => {
    const fetchArticles = (language) => {
      const sourceType = articleSourceType === 'articles' ? 'articles' : 'sp'; // Determine the source type
      const fetchSource = `/${sourceType}_${language}.json`; // Define fetchSource variable
      setIsLoading(true);
      fetch(fetchSource)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          setArticles(data);
          const today = new Date();
          let monthNames;

          if (i18n.language === 'nl') {
              monthNames = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
          } else if (i18n.language === 'fr') {
              monthNames = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
          } else {
              monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          }

          const todayDateString = `${today.getDate()} ${monthNames[today.getMonth()]}`;
          const todayIndex = data.findIndex(article => article.Date === todayDateString);
          if (todayIndex !== -1) {
            setCurrentArticleIndex(todayIndex);
          } else {
            setError(t('noArticle') + ' Date: ' + todayDateString);
            window.location.reload();
          }
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      fetchArticles(savedLanguage);
    } else {
      fetchArticles(i18n.language);
    }
  }, [i18n.language, articleSourceType, t]); // Add articleSourceType to dependency array



    const toggleTheme = () => {
      setIsDarkTheme(!isDarkTheme);
  };

  const adjustTextSize = (increment) => {
    setTextSize(currentSize => currentSize + increment);
  };

  const goToNextArticle = () => {
    setCurrentArticleIndex(index => (index + 1) % articles.length);
  };

  const goToPreviousArticle = () => {
    setCurrentArticleIndex(index => (index - 1 + articles.length) % articles.length);
  };

 useEffect(() => {
    const utterance = new SpeechSynthesisUtterance();
    utterance.lang = i18n.language === 'nl' ? 'nl-NL' : i18n.language === 'fr' ? 'fr-FR' : 'en-US'; // Set language based on current language
    utterance.rate = 0.85; // Slower pace
    setSpeechSynthesisUtterance(utterance);

    // Attempt to set voice when voices are loaded
    const setVoice = () => {
        const voices = window.speechSynthesis.getVoices();
        let voice;
        if (i18n.language === 'nl') {
            voice = voices.find(voice => voice.name === "Google Nederlands") || voices.find(voice => voice.lang === "nl-NL");
        } else if (i18n.language === 'fr') {
            voice = voices.find(voice => voice.name === "Google Français") || voices.find(voice => voice.lang === "fr-FR");
        } else {
            voice = voices.find(voice => voice.name === "Google UK English Female") || voices.find(voice => voice.lang === "en-US");
        }
        if (voice) {
            utterance.voice = voice;
            console.log(voice.name)
        } else {
            console.log("Voice not found, using default voice.");
        }
    };

    if (speechSynthesis.getVoices().length) {
        setVoice();
    } else {
        speechSynthesis.onvoiceschanged = setVoice;
    }

    // Cleanup function to remove the event listener
    return () => {
        speechSynthesis.onvoiceschanged = null;
    };
}, [i18n.language]);


  const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageSwitch = (selectedLanguage) => {
      handleLanguageSelect(selectedLanguage); // Call the handleLanguageSelect function defined in the parent component
    };

    const flagSrc = i18n.language === 'nl' ? '/flag_en.png' : '/flag_nl.png';
    const altText = i18n.language === 'nl' ? 'Switch to English' : 'Switch to Dutch';

    return (
      <div style={{ padding: '0px', margin: '0px' }}>
        <img src={flagSrc} alt={altText} title={altText} style={{ width: '20px', height: '20px' }} onClick={() => handleLanguageSwitch(i18n.language === 'nl' ? 'en' : 'nl')} />
      </div>
    );
  };

  // Toggle speech
  const toggleSpeech = () => {
    if (isSpeaking) {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      if (currentArticleIndex !== null) {
        const article = articles[currentArticleIndex];
        speechSynthesisUtterance.text = `${article.Title}. ${article.Intro}. ${article.Article}. ${article.Quote}`;
        speechSynthesis.speak(speechSynthesisUtterance);
        setIsSpeaking(true);

        speechSynthesisUtterance.onend = () => {
          setIsSpeaking(false); // Reset state when speech ends
        };
      }
    }
  };



  if (isLoading) return <div>{t('loading')}</div>;
  if (error) return <div>Error: {error}</div>;
  if (currentArticleIndex === null) return <div>{t('loading')}</div>;

  const article = articles[currentArticleIndex];
  const themeClass = isDarkTheme ? 'dark-theme' : 'light-theme';
  const MobileNavbarItem = styled.div`
  color: ${({ theme }) => theme === 'dark' ? 'var(--navbar-text-color)' : 'inherit'};
  /* Additional styling */
  `;

  return (
    <Styles.Wrapper>
    <CSSReset />
        <div className={`App ${themeClass}`} style={{ fontSize: `${textSize}px`, lineHeight: '1.6' }}>
         {showPopup && <PopupBanner message={popupMessage} onClose={handleClosePopup} />}
          {showEmailPopup && (
            <div className="popup-container">
                <div className="popup">
                  <form onSubmit={handleEmailSubmit}>
                    {t('receivedaily')}<br/><br />
                    <input type="email" id="email" placeholder="e-mail" className="popup-input" value={email} onChange={(e) => setEmail(e.target.value)}/> <br />
                    <input type="tel" id="phone" placeholder="+xxxxxxxxxxxx" className="popup-input" value={phone} onChange={(e) => setPhone(e.target.value)}/><br />
                    <br />
                    <button type="submit">{t('sentdaily')}</button>
                    <button type="cancel">{t('cancel')}</button>
                  </form>
                </div>
              </div>
          )}

          {showLanguagePopup && (
            <div className="popup-container">
              <div className="popup">
                <div>
                  <p>Please select your language:<br/><br /></p>
                </div>
              <button onClick={() => handleLanguageSelect('en')}>English</button><br />
              <button onClick={() => handleLanguageSelect('nl')}>Nederlands</button><br />
              <button onClick={() => handleLanguageSelect('fr')}>French</button><br />
             </div>
            </div>
          )}
          <div className="copyIcon" onClick={copyArticleToClipboard}>
          <File size={24} />
          </div>
          <div className="contactIcon" onClick={() => setShowEmailPopup(true)}>
          <MessageSquare size={24} />
          </div>
          <div className='volume' onClick={toggleSpeech}>
          {isSpeaking ? <VolumeX size={24} /> : <Volume2 size={24} />}
          </div>
         
        {isMobile ? (
        <MobileNavbar.Wrapper>
          <MobileNavbar.Items theme={isDarkTheme ? 'dark' : 'light'}>
            <MobileNavbar.Item onClick={goToPreviousArticle}>
              <ArrowLeftCircle size={24} />{t('previous')}
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={() => adjustTextSize(2)}>
              <PlusCircle size={24} />{t('fontplus')}
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={toggleSource}>
              <BookOpen size={24} />{getSourceLabel()}
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={() => adjustTextSize(-2)}>
              <MinusCircle size={24} />{t('fontmin')}
            </MobileNavbar.Item>
            <MobileNavbar.Item onClick={goToNextArticle}>
              <ArrowRightCircle size={24} />{t('next')}
            </MobileNavbar.Item>
          </MobileNavbar.Items>
        </MobileNavbar.Wrapper>
      ) : (
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => adjustTextSize(2)}>A+</button>
        <button onClick={() => adjustTextSize(-2)}>A-</button>
        <button className='image-btn'><LanguageSwitcher /></button>
         <button onClick={toggleSource}>
          {getSourceLabel()}
         </button> 
      </div>
      )}
      <button onClick={toggleTheme}>Dark Mode</button>
      <div>
        {articles.length > 1 && (
          <>
            <button onClick={goToPreviousArticle}>&lt;</button>
          </>
        )}
        <span>{article.Date}</span>
        {articles.length > 1 && (
          <>
            <button onClick={goToNextArticle}>&gt;</button>
          </>
        )}
      </div>
      <h1>{article.Title}</h1>
      <p>{article.Intro}</p>
      <p>{article.Article}</p>
      <footer>
        <p><br/>{article.Source}<br/><br/></p>
        <blockquote><b>"{article.Quote}"</b></blockquote>
      </footer>
      <center><br/>Unofficial NA Tool - Free to Use - 12 Step Recovery - For All My Fellows - Some Text is Altered - Original Texts and Copyright: NA World Service</center>
    </div>
    </Styles.Wrapper>
  );
}

const Styles = {
  Wrapper: styled.main`
    display: flex;
    background-color: #eeeeee;
    height: 100vh;
  `
};

const Navbar = {
  Wrapper: styled.nav`
    flex: 1;

    align-self: flex-start;

    padding: 1rem 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
  `,
  Logo: styled.h1`
    border: 1px solid gray;
    padding: 0.5rem 1rem;
  `,
  Items: styled.ul`
    display: flex;
    list-style: none;
  `,
  Item: styled.li`
    padding: 0 1rem;
    cursor: pointer;
  `
};

const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
    position: fixed;
    width: 100vw;
    bottom: 0;

    justify-content: center;
  `,
  Items: styled(Navbar.Items)`
    flex: 1;
    padding: 0 2rem;

    justify-content: space-around;
  `,
  Item: styled(Navbar.Item)`
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.2rem;
  `,
  Icon: styled.span``
};

const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
    margin: 0; 
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%; /*1rem = 10px*/
    box-sizing: border-box;      
  }  

  body {
    font-size: 1.4rem;
    font-family: sans-serif;
  }
`;

export default App;